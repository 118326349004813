// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");
import storage from "../../../framework/src/StorageProvider";

import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import moment from "moment";
export interface Props {
  navigation: any;
  id: string;
}

export interface JobListDataType {
  attributes: {
    addresses: [];
    address: string;
    created_at: string;
    company_name: string;
    company_headquarter: string;
    company_photo: string;
    company_photo_url: string;
    company_page_id: number;
    country: string;
    employment_type: null;
    employment_type_id: number;
    followers_count: number;
    industry_id: number;
    job_description: string;
    job_title: string;
    job_video: string;
    job_video_url: string;
    location: string;
    job_function: string;
    other_skills: Array<string>;
    profile_id: number;
    industry_name: string;
    question_answer_id: Array<string>;
    question_answers: Array<object>;
    salary: string;
    preffered_location: Array<string>;
    seniority_level: string;
    skill_name: Array<string>;
    sub_emplotyment_type: null;
    total_inteview_rounds: number;
    image_url: string;
    remote_job: boolean;
    skill_id: Array<number>;
  };
  id: string;
  type: string;
}

export interface JobListIOSDataType {
  item: {
    attributes: {
      address: string;
      addresses: [];
      company_headquarter: string;
      company_name: string;
      company_page_id: number;
      company_photo: string;
      company_photo_url: string;
      country: string;
      created_at: string;
      employment_type: null;
      employment_type_id: number;
      industry_id: number;
      industry_name: string;
      job_description: string;
      job_function: string;
      job_title: string;
      job_video: string;
      job_video_url: string;
      location: string;
      other_skills: Array<string>;
      preffered_location: Array<string>;
      profile_id: number;
      question_answer_id: Array<string>;
      question_answers: Array<object>;
      remote_job: boolean;
      salary: string;
      seniority_level: string;
      skill_id: Array<number>;
      skill_name: Array<string>;
      sub_emplotyment_type: null;
      total_inteview_rounds: number;
      image_url: string;
      followers_count: number;
    };
    id: string;
    type: string;
  };
}


export interface AttributesTeacher {
  activated: boolean;
  email: string | null;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  password: string | null;
  member_id: string | null;
  organization: string | null;
  street_address: string;
  city: string;
  state: string;
  zip_code: number;
  country: string;
  no_of_employees: number | null;
  program_type: string | null;
  preferred_travel_distance: number;
  program_capacity: number | null;
  about_us: string | null;
  allow_sms_notification: boolean;
  sms_recommendation: boolean;
  role: Role;
  profile_image: string;
  upload_resumes: string;
  letters_of_interests: string;
  credentials: string;
  transcripts: string;
  note_pages: string;
  letter_of_reference_emails: string;
  country_code: string | null;
  phone_number: string | null;
  preferred_modality_ids: number[];
  preferred_modality_name: string[];
  preferred_age_group_ids: number[];
  preferred_age_group_name: string[];
  preferred_working_days_ids: number[];
  preferred_working_days_name: string[];
  preferred_nature_of_jobs_ids: number[];
  preferred_nature_of_jobs_name: string[];
  preferred_time_shifts_ids: number[];
  preferred_time_shifts_name: string[];
  document_sharings_ids: number[];
  document_sharings_name: string[];
}

interface Role {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface Account {
  id: string;
  type: string;
  attributes: AttributesTeacher;
}
interface Pagination {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number ;
}

export interface CardsDetails {
  id: string;
  type: string;
  attributes: JobAttributes;
}

interface JobAttributes {
  id: number;
  job_title: string;
  job_description: string;
  organization: string;
  education_level: string;
  experience_required: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  about_organization: string;
  location: string;
  role_name: string;
  posted_by: string;
  job_image: string;
  account_image: AccountImage;
  created_at: string;
  key_responsibilities: string;
  qualifications: string;
  benefits: string;
  posted_at: string;
  admin_email: string;
  post_created_at: string;
  job_applied_at: string | null;
}

interface AccountImage {
  id: number;
  url: string;
  type: string;
  filename: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  first: boolean;
  searchText: string;
  jobArray: JobListDataType[];
  recent: Array<string>;
  lastSearch: string;
  loading: boolean;
  followIndex: number;
  token: string;
  bachelorCheck: boolean;
  associateCheck: boolean;
  highSchoolCheck: boolean;
  certificateCheck: boolean;
  selectedState: string;
  value: number;
  isToken: boolean;
  showFilterBox: boolean;
  selectedDateFrom: string;
  selectedDateTo: string;
  currentPage: number;
  cardsPerPage: number;
  enteredText: string;
  selectedItems: string[];
  options: string[];

  searchedResult: CardsDetails[];
  searchedResultTeacher:any[]
  cardsDetails: CardsDetails[];
  isViewAll: boolean;
  noLoginRecentJobs: CardsDetails[];
  noLoginInterestedJobs: CardsDetails[];
  logInMatchingProfileJobs: CardsDetails[];
  loginRecentJobs: CardsDetails[];
  loginInterestedJobs: CardsDetails[];

  searchedItemText: string;
  isSearchEmpty: boolean;
  isFilterOptionEmpty: boolean;
  datePickerIsOpen: boolean;
  ageError: boolean;
  datePickerToIsOpen: boolean;
  isSearchedClicked: boolean;
  anchorEl: null | HTMLElement;
  selectedSortItem: string;
  isSortDropOption: boolean;
  nameOfPageWhichShowingFull: string | null;
  searchedResultCopy: CardsDetails[],
  searchedResultTeacherCopy :any[]
  recentJobsCopy : CardsDetails[],
  matchingProfileJobsCopy : CardsDetails[],
  interestedJobsCopy : CardsDetails[],
  current_page:number,
  next_page:number,
  prev_page:number| null,
  total_count: number,
  total_pages : number,
  recentJobsPagination : Pagination | null,
  interestedJobsPagination : Pagination | null,
  matchingJobsPagination : Pagination | null,
  pageSize : number,
  inviteFriend:boolean;
  searchedResultFromApi : CardsDetails[],
  showAll: boolean,
  role : number,
  substituteTeacherDetails : any[],
  substitueTeacherCopy : any[],
  isDrawerOpen:boolean
}

interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class JobListingController extends BlockComponent<Props, S, SS> {
  searchJobApiCallId: string = "";
  teacherSearchId:string="";
  searchCompanyApiCallId: string = "";
  searchPeopleApiCallId: string = "";
  followCompanyApiCallId: string = "";
  //willFocusSubscription: object;
  addConnectionApiCallId: string = "";
  lastVisitedJob: string = "";
  teacherListId:string="";
  debounceTimer:any=0;
  lastVisitedCompany: string = "";
  lastVisitedPeople: string = "";

  noLoginRecentJobsId: string = "";
  noLoginInterestedJobsId: string = "";
  logInMatchingProfileJobsId: string = "";
  loginRecentJobsId: string = "";
  loginInterestedJobsId: string = "";
  debounceHandler:any

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.debounceHandler = this.debounce(this.handleSearchButtonOnClick , 1000)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      loading: false,
      txtSavedValue: "A",
      enableField: false,
      first: true,
      searchText: "",
      jobArray: [],
      recent: [],
      lastSearch: "",
      followIndex: -1,
      token: "",
      bachelorCheck: false,
      associateCheck: false,
      highSchoolCheck: false,
      certificateCheck: false,
      selectedState: "",
      value: 0,
      isToken: false,
      showFilterBox: false,
      selectedDateFrom: "",
      selectedDateTo: "",
      currentPage: 1,
      cardsPerPage: 2,
      enteredText: "",
      selectedItems: [],
      options: [
        "Assistant Teacher",
        "Assistant Professor",
        "Assistant Lecturer ",
        "Lab Assistant",
      ],

      isViewAll: false,
      noLoginRecentJobs: [],
      noLoginInterestedJobs: [],
      logInMatchingProfileJobs: [],
      loginRecentJobs: [],
      loginInterestedJobs: [],
      cardsDetails: [],
      searchedResult: [],
      searchedResultTeacher :[],
      searchedResultTeacherCopy:[],
      searchedItemText: "",
      isSearchEmpty: false,
      isFilterOptionEmpty: false,
      datePickerIsOpen: false,
      ageError: false,
      datePickerToIsOpen: false,
      isSearchedClicked: false,
      anchorEl: null,
      selectedSortItem: "Relevance",
      isSortDropOption: false,
      nameOfPageWhichShowingFull: "",
      searchedResultCopy: [],
      interestedJobsCopy:[],
      matchingProfileJobsCopy:[],
      recentJobsCopy:[],
      current_page:1,
      next_page:2,
      prev_page:null,
      total_count:1,
      recentJobsPagination : null,
      interestedJobsPagination : null,
      matchingJobsPagination : null,
      total_pages : 1,
      pageSize : 10,
      inviteFriend:false,
      searchedResultFromApi:[],
      showAll:false,
      role:0,
      substituteTeacherDetails:[],
      substitueTeacherCopy:[],
      isDrawerOpen:false
};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  compiledJobs = () => {    
    return this.state.searchedResultFromApi
  };

  handleToggle = ()=>{
    this.setState({
      showAll : !this.state.showAll ?true :false
    })
  }

  handleViewALL = () => {
    this.setState({ isViewAll: true });
  };

  getJobSearchSuccessCallBack = (
    responseJson: CardsDetails[],
  ) => {
        this.setState({ searchedResultFromApi: responseJson || [], loading: false });
        this.handleSearch(responseJson)
    }
  getJobSearchFailedCallBack = (message: string, apiRequestCallId: string) => {
    if (message.includes("Record not found")) {
      if (apiRequestCallId === this.searchJobApiCallId) {
        this.lastVisitedJob = this.state.searchText?.trim();
        this.setState({ jobArray: [], loading: false });
      }
    } else if (
      apiRequestCallId === this.addConnectionApiCallId &&
      message.includes("Connection created successfully")
    ) {
      alert("Request sent successfully");
    }
  };

  getJobSearchErrorCallBack = (
    errorReponse: object,
    apiRequestCallId: string
  ) => {
    if (
      apiRequestCallId === this.searchJobApiCallId ||
      apiRequestCallId === this.searchCompanyApiCallId ||
      apiRequestCallId === this.searchPeopleApiCallId
    ) {
      this.parseApiErrorResponse(errorReponse);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  shortString = (value: string) => {
    if (value?.length <= 100) {
      return value;
    }
    return value?.slice(0, 100) + "...";
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {});
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.teacherListId) {
        this.setState({
          substituteTeacherDetails : responseJson.data,
          substitueTeacherCopy : responseJson.data
        })
      }
      if(apiRequestCallId === this.teacherSearchId)
      {
        this.handleSearchTeacher(responseJson.data)
      }
      
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.searchJobApiCallId && apiRequestCallId) {
        this.getJobSearchSuccessCallBack(responseJson.data);
      }
      if (responseJson?.meta?.message) {
        this.getJobSearchFailedCallBack(
          responseJson.meta.message,
          apiRequestCallId
        );
      }

      if (errorReponse) {
        this.getJobSearchErrorCallBack(errorReponse, apiRequestCallId);
      }
      
      this.receiveJobListing(apiRequestCallId, responseJson);    
    }
  }

  async receiveJobListing(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId == this.noLoginRecentJobsId) {   
      this.setState({
        noLoginRecentJobs: responseJson.jobs.data,
      });
    }

    if (apiRequestCallId == this.noLoginInterestedJobsId) {
      this.setState({
        noLoginInterestedJobs: responseJson.jobs.data,
      });
    }

    if (apiRequestCallId == this.logInMatchingProfileJobsId) {
     this.setState({
        logInMatchingProfileJobs: responseJson.jobs.data,
        matchingProfileJobsCopy : responseJson.jobs.data,
        matchingJobsPagination : responseJson.meta.pagination
      });
    }

    if (apiRequestCallId == this.loginRecentJobsId) {
      this.setState({
        loginRecentJobs: responseJson.jobs.data,
        recentJobsCopy : responseJson.jobs.data,
        recentJobsPagination : responseJson.meta.pagination
      });
    }

    if (apiRequestCallId == this.loginInterestedJobsId) { 
      this.setState({
        loginInterestedJobs: responseJson.jobs.data,
        interestedJobsCopy:responseJson.jobs.data,
        interestedJobsPagination : responseJson.meta.pagination
      });
    }
  }


  handleViewForMatchingProfileJobs = (page: string) => {
    if (page === "matchingProfile") {
      this.setState({
        nameOfPageWhichShowingFull: "Matching your profile",
        total_pages  : this.state.matchingJobsPagination?.total_pages || 1,
        total_count : this.state.matchingJobsPagination?.total_count || 1
      })
      window.scrollTo(0, 0)
    }
  }

  handleViewForTopCandidates = (page: string , data:any[]) => {
    if (page === "topcandidates") {
      this.setState({
        nameOfPageWhichShowingFull: "Top Candidates",
        total_pages  : data.length,
        total_count : this.state.matchingJobsPagination?.total_count || 1
      })
      window.scrollTo(0, 0)
      const paginateData = this.paginateResults(data ,  this.state.current_page);
      this.setState({
        substituteTeacherDetails : paginateData,
        total_count : data.length,
        total_pages : Math.ceil(data?.length / this.state.pageSize),
      })
      
    }
  }


  handleViewForRecentlyAddedJobs = (page: string) => {
    if (page === "recentlyadded") {
      this.setState({
        nameOfPageWhichShowingFull: "Recently added",
        total_pages  : this.state.recentJobsPagination?.total_pages || 1,
        total_count : this.state.recentJobsPagination?.total_count || 1
      })
      window.scrollTo(0, 0)
    }

  }

  handleViewInterestedJobs = (page: string) => {
    if (page === "youmayintrestedin") {
      this.setState({
        nameOfPageWhichShowingFull: "You may be interested in",
        total_pages  : this.state.interestedJobsPagination?.total_pages || 1,
        total_count : this.state.interestedJobsPagination?.total_count || 1
      })
      window.scrollTo(0, 0)
    }
   
  }


  navigateToBack = () => {
    this.setState({
      nameOfPageWhichShowingFull: "",
      searchedItemText: "",
      searchedResult: [],
      selectedSortItem : "Relavance",
      total_count : 0 , 
      total_pages : 0
    })
  }

  getToken = () => {
    const getAuthToken: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(getAuthToken);
  };

  async componentDidMount() {
    const token = await storage.get("loginToken");
    const role = await storage.get("role");
    this.setState({ isToken: token  , role : role});
    this.getToken();
    this.getSubstituteTeacherList()
    this.getNotLoginRecentlyAddedJobs(this.state.current_page);
    this.getNotLoginInterestedJobs(this.state.currentPage);
    this.getLoginMatchingProfileJobs(this.state.currentPage);
    this.getLoginRecentAddedJobs(this.state.current_page);
    this.getLoginInterestedJobs(this.state.current_page);
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); 
  }

  async getRecents() {
    const recentData = await getStorageData("recent");
    this.setState({ recent: recentData });
  }
  handler = (inputValue: string) => {
    if (inputValue.trim()) {
      this.setState({
        lastSearch: inputValue.trim(),
        searchText: inputValue,
        loading: true,
      });
      
    }
  };
  handleResize = () => {
    if (window.innerWidth > 571 && this.state.isDrawerOpen) {
      this.toggleDrawer(false);
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null, isSortDropOption: false });
  }

  handleSortDropDownClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event?.currentTarget, isSortDropOption: true });
  }

   
  parseDateTime = (dateStr: string, timeStr: string): Date => {
    
    const [day, month, year] = dateStr?.split('/').map(Number);

    let [hours, minutes] = [0, 0];
    let isPM = false;

    const timeParts = timeStr.split(':');
    if (timeParts.length === 2) {
      hours = parseInt(timeParts[0].trim(), 10);
      minutes = parseInt(timeParts[1].trim(), 10);
  
      if (timeStr.toUpperCase().includes('PM')) {
        isPM = true;
      }
    }
    if (hours === 12) {
      hours = 0; 
    }
    if (isPM) {
      hours += 12; 
    }
    return new Date(year, month - 1, day, hours, minutes);
  };

  sortData = (data: CardsDetails[], order:string)=> {
    return data.sort((a, b) => {
      const dateA = this.parseDateTime(a.attributes.posted_at, a.attributes.post_created_at);
      const dateB = this.parseDateTime(b.attributes.posted_at, b.attributes.post_created_at);

      const comparison = dateA.getTime() - dateB.getTime();
      return order === 'asc' ? -comparison : comparison;
    });
  };
  

  sortTeacherData=(data:any, order:any) =>{ 
    return data.sort((a:any, b:any) => {
        const dateA = moment(a.attributes.created_at, 'DD/MM/YYYY');
        const dateB = moment(b.attributes.created_at, 'DD/MM/YYYY');

        if (order === 'asc') {
            return dateB.diff(dateA);
        } else if (order === 'desc') {
            return dateA.diff(dateB);
        }
    });
}

  handleSort = (event: React.MouseEvent<HTMLElement>, order: string) => {
    const listForSortData = {
      copyOfSearchResult: Array.isArray(this.state.searchedResult) ? [...this.state?.searchedResult]:[],
      copyOfMatchingProfile: [...this.state.logInMatchingProfileJobs],
      copyOfRecentJobs: [...this.state.loginRecentJobs],
      copyOfInterstedJobs: [...this.state.loginInterestedJobs],
      copyOfTeacherList:[...this.state.substituteTeacherDetails],
      copyOfSearchedTeacherList:[...this.state.searchedResultTeacher]
    };
  
    const sortBySearch = this.sortData(listForSortData.copyOfSearchResult, order);
    const sortMatchingProfile = this.sortData(listForSortData.copyOfMatchingProfile, order);
    const sortByRecent = this.sortData(listForSortData.copyOfRecentJobs, order);
    const sortByInterested = this.sortData(listForSortData.copyOfInterstedJobs, order);
    const sortBySearchTeacher = this.sortTeacherData(listForSortData.copyOfSearchedTeacherList , order)
    const sortByTeacherList = this.sortTeacherData(listForSortData.copyOfTeacherList , order)
  
    this.setState({
      searchedResult: sortBySearch,
      selectedSortItem: event?.currentTarget?.textContent || "",
      loginRecentJobs: sortByRecent,
      loginInterestedJobs: sortByInterested,
      logInMatchingProfileJobs: sortMatchingProfile,
      anchorEl: null,
      substituteTeacherDetails : sortByTeacherList,
      searchedResultTeacher:sortBySearchTeacher,
      isSortDropOption: false,
    });
  };
  
  handleListingRelavance = (event: React.MouseEvent<HTMLElement>) => {
    const paginateData = this.paginateResults(this.state.substitueTeacherCopy ,  this.state.current_page);
    const paginateDataSearch = this.paginateResults(this.state.searchedResultTeacherCopy ,  this.state.current_page);
    this.setState({
      searchedResult: this.state.searchedResult,
      selectedSortItem: event?.currentTarget?.textContent || "",
      loginRecentJobs: this.state.recentJobsCopy,
      loginInterestedJobs: this.state.interestedJobsCopy,
      logInMatchingProfileJobs: this.state.matchingProfileJobsCopy,
      substituteTeacherDetails : paginateData,
      searchedResultTeacher:paginateDataSearch,
      anchorEl: null,
      isSortDropOption: false
    });
  };
  
  handleListingMostRecentData = (event: React.MouseEvent<HTMLElement>) => {
    this.handleSort(event, 'asc');
  };
  
  handleListingLeastRecentData = (event: React.MouseEvent<HTMLElement>) => {
    this.handleSort(event, 'desc');
  };

  handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement>,
    newPage: number) =>{
    this.setState({current_page : newPage})
    if (this.state.searchedResultTeacher.length === 0 &&this.state.searchedResult?.length === 0 && this.state?.searchText === "") {
      this.state.nameOfPageWhichShowingFull === 'Recently added' && this.getLoginRecentAddedJobs(newPage)
      this.state.nameOfPageWhichShowingFull === 'Matching your profile' && this.getLoginMatchingProfileJobs(newPage)
      this.state.nameOfPageWhichShowingFull === 'You may be interested in' && this.getLoginInterestedJobs(newPage)
    }
    else{    
      this.state.role == 2 && this.paginateResults(this.state.searchedResultCopy , newPage)
      this.state.role == 1 && this.state.isSearchEmpty === true &&this.paginateResults(this.state.searchedResultTeacherCopy , newPage)
      this.state.role == 1 && this.state.isSearchEmpty === false &&this.paginateResults(this.state.substitueTeacherCopy , newPage)
    }
    window.scrollTo(0, 0)
  }

  addDataToRecent() {
    let recent = this.state.recent;
    this.setState({ recent }, () => {
      setStorageData("recent", JSON.stringify(this.state.recent));
    });
  }

  // fetch jobs apis - not logged in
  getNotLoginRecentlyAddedJobs = (page:number) => {
    const getNotLoggedInRecentJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.noLoginRecentJobsId = getNotLoggedInRecentJobsMsg.messageId;

    getNotLoggedInRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/recently_added_jobs?page=${page}`
    );

    getNotLoggedInRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET` 
    );

    runEngine.sendMessage(
      getNotLoggedInRecentJobsMsg.id,
      getNotLoggedInRecentJobsMsg
    );
    
  };
  

  getNotLoginInterestedJobs = (page:number) => {
    const getNotLoggedInInterestJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.noLoginInterestedJobsId = getNotLoggedInInterestJobsMsg.messageId;

    getNotLoggedInInterestJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/jobs_you_may_be_interested_in?page=${page}`
    );

    getNotLoggedInInterestJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(
      getNotLoggedInInterestJobsMsg.id,
      getNotLoggedInInterestJobsMsg
    );
  };

  // fetch jobs apis - logged in
  getLoginRecentAddedJobs = async (page:number) => {
    const token = await storage.get("loginToken");

    const header = { token };

    const getLoginRecentJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginRecentJobsId = getLoginRecentJobsMsg.messageId;

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/jobs_recently_added?page=${page}`
    );

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getLoginRecentJobsMsg.id, getLoginRecentJobsMsg);
  };

  getLoginInterestedJobs = async (page:number) => {
    const token = await storage.get("loginToken");

    const header = { token };

    const getLoginInterestedJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginInterestedJobsId = getLoginInterestedJobsMsg.messageId;

    getLoginInterestedJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/jobs_you_may_be_interested_in?page=${page}`
    );

    getLoginInterestedJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getLoginInterestedJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(
      getLoginInterestedJobsMsg.id,
      getLoginInterestedJobsMsg
    );
  };

  getLoginMatchingProfileJobs = async (page:number) => {
    const token = await storage.get("loginToken");

    const header = { token };

    const getLoginMatchingJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logInMatchingProfileJobsId = getLoginMatchingJobsMsg.messageId;

    getLoginMatchingJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/show_jobs_matching_your_profile?page=${page}`
    );

    getLoginMatchingJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getLoginMatchingJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getLoginMatchingJobsMsg.id, getLoginMatchingJobsMsg);
  };

  goToJobDetailPage = (
    jobId: string,
    companyName: string,
    companyLocation: string,
    companyLogo: string
  ) => {
    const jobMessage = new Message(getName(MessageEnum.NavigationMessage));
    jobMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "JobDetailsPage"
    );

    jobMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      companyName,
      companyLocation,
      companyLogo,
      jobId,
    });
    jobMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );

    this.send(jobMessage);
  };

  searchJob = async (inputValue: string) => {
    this.setState({ loading: true });
    const token = await storage.get("loginToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_joblisting/job_creations/search_job?job_title=" + inputValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  searchTeacher = async () => {
   
    this.setState({ loading: true });
    const token = await storage.get("loginToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.teacherSearchId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/substitute_teacher_list`  
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUrl = (imageUrl: string) => {
    if (imageUrl.includes("https://") || imageUrl.includes("http://")) {
      return imageUrl;
    } else {
      return config.baseURL + imageUrl;
    }
  };

  handleBachelor = () => {
    this.setState({ bachelorCheck: !this.state.bachelorCheck });
    this.debounceHandler()
  };
  handleAssociat = () => {
    this.setState({ associateCheck: !this.state.associateCheck });
    this.debounceHandler()
  };
  handlehighScool = () => {
    this.setState({ highSchoolCheck: !this.state.highSchoolCheck });
    this.debounceHandler()
  };
  handlecertificate = () => {
    this.setState({ certificateCheck: !this.state.certificateCheck });
    this.debounceHandler()
  };
  handleStateSelect = (selectedState: string) => {
    if (this.state.selectedState) {
      this.setState({ selectedState  :"" });  
      this.debounceHandler()
    }
    else{
      this.setState({ selectedState });
      this.debounceHandler()
    }
    this.debounceHandler()
  };
  debounce = (func: Function, delay: number) => {
    return (...args: any[]) => {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  
  handleChange = (event: ChangeEvent<{}>, value: number | number[]) => {
    this.setState({ value: value as number });
    this.debounceHandler()
  };

  handleTextFieldChange = (event: { target: { value: string } }) => {
    const { value } = event.target;
    this.setState({ enteredText: value, showFilterBox:!!value });
  };

  handleDateFrom = (event: { target: { value: any } }) => {
    const { value } = event.target;
    this.setState({ selectedDateFrom: value });
    this.debounceHandler()
  };
  handleDateTo = (event: { target: { value: any } }) => {
    const { value } = event.target;
    this.setState({ selectedDateTo: value });
    this.debounceHandler()
  };

  paginateResults = (data:any[], newPage:number) => {
    this.setState({
      current_page : newPage
    })
    const {pageSize} = this.state;
    const startIndex = (newPage - 1) * pageSize;
    const paginatedResults = data?.slice(startIndex, startIndex + pageSize);
    if (this.state.role == 1) {
      this.setState({ searchedResultTeacher: paginatedResults });
    }
    else{
      this.setState({ searchedResult: paginatedResults });
    }
    return paginatedResults
  };

  handleSearchButtonOnClick = () =>{ 
    const { enteredText } = this.state;
    this.state.role == 1 ? this.searchTeacher():
    this.searchJob(enteredText)
  }
  
  handleSearch = (responseJson : CardsDetails[]) => {
    const { enteredText, pageSize } = this.state;
    this.setState({ isSearchedClicked: true, currentPage: 1 });    
    const filteredCards: CardsDetails[] = responseJson

    if (enteredText.trim() === "") {
      this.setState({ isSearchEmpty: false, isSearchedClicked: false, searchedResult: this.state.cardsDetails });
    } else if (filteredCards?.length === 0) {
      this.setState({ isSearchEmpty: true, searchedResult: filteredCards });
    } else {
    
      this.setState({ searchedResult: filteredCards, isSearchEmpty: false, searchedResultCopy: filteredCards, total_count: filteredCards?.length });
    }
    
    const filteredCardsOptions: CardsDetails[] = Array.isArray(filteredCards)?this.filterSearch(filteredCards) : [];
    
    if (filteredCardsOptions?.length === 0) {
      this.setState({ searchedResult: [], searchedItemText: enteredText, isFilterOptionEmpty: true });
    } else {
      this.setState({ searchedResult: filteredCardsOptions, searchedItemText: enteredText, isFilterOptionEmpty: false, searchedResultCopy: filteredCardsOptions, total_count: filteredCardsOptions?.length, total_pages: Math.ceil(filteredCardsOptions?.length / pageSize) });
    }
  
    if (enteredText === "") {
      this.setState({ isFilterOptionEmpty: false, searchedResult: this.state.cardsDetails, searchedResultCopy: this.state.cardsDetails });
    }
    this.paginateResults(filteredCardsOptions , this.state.current_page);
  };

  filterSearch = (filteredCards: CardsDetails[]): CardsDetails[] => {
    const {
      enteredText,
      bachelorCheck,
      associateCheck,
      highSchoolCheck,
      certificateCheck,
      selectedState,
      value,
      selectedDateFrom,
      selectedDateTo,
    } = this.state;

    const filterByText = (card: any) =>
      enteredText.trim() !== ''
        ? card.attributes.job_title.toLowerCase().includes(enteredText.toLowerCase())
        : true;
  
    const filterByGraduation = (card: any) => {
      const graduation = card.attributes.education_level;
      if (bachelorCheck && graduation !== "Bachelor's degree") return false;
      if (associateCheck && graduation !== "Associate's degree") return false;
      if (highSchoolCheck && graduation !== "High school diploma or equivalent") return false;
      if (certificateCheck && graduation !== "Certification in substitute teaching") return false;
      return true;
    };
  
    const filterByYearsOfExperience = (card: any) => {
      const yearsOfExperience = parseInt(card.attributes.experience_required);
      
      return value > 0 ? yearsOfExperience >= value : true;
    };
  
    const filterBySelectedState = (card: any) =>
      selectedState !== '' ? card.attributes.state !== null && card.attributes.state.toLocaleLowerCase
        ().includes(selectedState.toLocaleLowerCase()) : true;
  
    const filterByDateRange = (card: any) => {
      const dateFormat = 'DD/MM/YY';
      const postedDate = moment(card.attributes.posted_at, dateFormat);
      if (!postedDate.isValid()) {
        return false;
      }
      const fromDatePicker = selectedDateFrom ? moment(selectedDateFrom, "MM/DD/YY") : null;
      const toDatePicker = selectedDateTo ? moment(selectedDateTo, "MM/DD/YY") : null;
     
      const isafterFromDate = fromDatePicker ? postedDate.isSameOrAfter(fromDatePicker) : true;
      const isbeforeToDate = toDatePicker ? postedDate.isSameOrBefore(toDatePicker) : true;
      
      return isafterFromDate && isbeforeToDate;
    };
    
    const filteredCardsOptions:any = Array.isArray(filteredCards) &&filteredCards?.filter(
      (card) =>{
        return filterByDateRange(card) &&
        filterByText(card) &&
        filterByGraduation(card) &&
        filterByYearsOfExperience(card) &&
        filterBySelectedState(card)
      }  
    )

    this.setState({
      selectedSortItem: "Relevance",
      total_count: filteredCardsOptions?.length,
      total_pages: Math.ceil(filteredCardsOptions?.length / this.state.pageSize),
    });
  
    return filteredCardsOptions;
  };

  handleSearchTeacher = (responseJson: any[]) => {
    const { enteredText, pageSize, currentPage } = this.state;
  
  const [firstName , lastName] = enteredText.trim().split(" ")

  this.setState({ isSearchedClicked: true, currentPage: 1 });
  const filteredCards: any[] = responseJson.filter((item) => {
    const { first_name, last_name } = item.attributes;
  
    if (first_name && last_name) {
      
        return (first_name.toLocaleLowerCase().includes(firstName.toLocaleLowerCase()) || last_name.toLocaleLowerCase().includes(firstName.toLocaleLowerCase())) || 
        (lastName&&(first_name.toLocaleLowerCase().includes(lastName.toLocaleLowerCase()) || last_name.toLocaleLowerCase().includes(lastName.toLocaleLowerCase())))  
      
    }
  
    return false;
  });
    if (enteredText.trim() === "") {
      this.setState({ isSearchEmpty: false, isSearchedClicked: false, searchedResultTeacher: this.state.cardsDetails });
    } else if (filteredCards.length === 0) {
      this.setState({ isSearchEmpty: true, searchedResultTeacher: filteredCards });
    } else {
      this.setState({ 
        searchedResultTeacher: filteredCards,
        isSearchEmpty: false,
        searchedResultTeacherCopy: filteredCards,
        total_count: filteredCards.length,
        total_pages: Math.ceil(filteredCards.length / pageSize)
      });
    }
  
    const filteredCardsOptions: any[] = this.filterSearchTeacher(filteredCards);
    if (filteredCardsOptions.length === 0) {
      this.setState({
        searchedResultTeacher: [],
        searchedItemText: enteredText,
        isFilterOptionEmpty: true
      });
    } else {
      this.setState({
        searchedResultTeacher: filteredCardsOptions,
        searchedItemText: enteredText,
        isFilterOptionEmpty: false,
        searchedResultTeacherCopy: filteredCardsOptions,
        total_count: filteredCardsOptions.length,
        total_pages: Math.ceil(filteredCardsOptions.length / pageSize)
      });
    }
  
    if (enteredText === "") {
      this.setState({
        isFilterOptionEmpty: false,
        searchedResultTeacher: [],
        searchedResultTeacherCopy: []
      });
    }
    this.paginateResults(filteredCardsOptions, currentPage);
  };
  
  filterSearchTeacher = (filteredCards: any[]): any[] => {
    
    const {
      bachelorCheck,
      associateCheck,
      highSchoolCheck,
      certificateCheck,
      selectedState,
      value,
      selectedDateFrom,
      selectedDateTo,
    } = this.state;
  
    const filterByGraduation = (card: any) => {
      const qualifications = card.attributes.qualifications;
    
      if (!qualifications || qualifications.length === 0) {
        return true;
      }
    
      return qualifications.some((qualification: any) => {
        const graduation = qualification.course;
        return (
          (!bachelorCheck || graduation === "Bachelor's degree") &&
          (!associateCheck || graduation === "Associate's degree") &&
          (!highSchoolCheck || graduation === "High school diploma or equivalent") &&
          (!certificateCheck || graduation === "Certification in substitute teaching")
        );
      });
    };
  
    const filterByYearsOfExperience = (card: any) => {
      const yearsOfExperience = parseInt(card.attributes.total_experience);
      return value > 0 ? yearsOfExperience >= value : true;
    };
  
    const filterBySelectedState = (card: any) => 
      selectedState ? card.attributes.state?.toLowerCase().includes(selectedState.toLowerCase()) : true;
  
    const filterByDateRange = (card: any) => {
      const postedDate = moment(card.attributes.created_at, 'DD/MM/YYYY');
      const fromDatePicker = selectedDateFrom ? moment(selectedDateFrom, "MM/DD/YY") : null;
      const toDatePicker = selectedDateTo ? moment(selectedDateTo, "MM/DD/YY") : null;      

      return (
        (!fromDatePicker || postedDate.isSameOrAfter(fromDatePicker)) &&
        (!toDatePicker || postedDate.isSameOrBefore(toDatePicker))
      );
    };
  
    const filteredCardsOptions = filteredCards.filter(
      (card) =>
      {
        const meetsGraduation = filterByGraduation(card);
        const meetsExperience = filterByYearsOfExperience(card);
        const meetsState = filterBySelectedState(card);
        const meetsDateRange = filterByDateRange(card);
        return meetsExperience && meetsState && meetsDateRange &&meetsGraduation;
      }
    );
  
    this.setState({
      selectedSortItem: "Relevance",
      total_count: filteredCardsOptions.length,
      total_pages: Math.ceil(filteredCardsOptions.length / this.state.pageSize),
    });
  
    return filteredCardsOptions;
  };

  clearSearchText = () => {
    this.setState({ enteredText: '',isSearchedClicked:false });
    this.debounceHandler()
  }

  handleChipDelete = (item: string) => {
    this.debounceHandler()
    if (item === "Bachelor's degree") {
      this.setState({ bachelorCheck: false });
    } else if (item === "Associate's degree") {
      this.setState({ associateCheck: false });
    } else if (item === "High school diploma or equivalent") {
      this.setState({ highSchoolCheck: false });
    } else if (item === "Certification in substitute teaching") {
      this.setState({ certificateCheck: false });
    } else if (item.startsWith("Exp -")) {
      this.setState({ value: 0 });
    } else if (item.startsWith("From -")) {
      this.setState({ selectedDateFrom: "" });
    } else if (item.startsWith("To -")) {
      this.setState({ selectedDateTo: "" });
    } else if (this.state.selectedState) {
      this.setState({ selectedState: "" });
    }   
  };


  clearAll = () => {
    this.setState({
      bachelorCheck: false,
      associateCheck: false,
      highSchoolCheck: false,
      certificateCheck: false,
      value: 0,
      selectedState: "",
      selectedDateFrom :"",
      selectedDateTo : ""
    });
    this.debounceHandler()
  };

  viewDetailPageTeacher = (index: number) => {
    if(this.state.isToken){
      storage.set("profileId" , index)
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'TeacherListingDetails'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
  };

  viewDetailPage = (index: number) => {
    if(this.state.isToken){
      storage.set('jobId',index)
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'JobListingDetails'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
  };

  handleChanges = (selectedDate: any) => {
    this.setState({
      selectedDateFrom: moment(selectedDate).format("MM/DD/YY"),
      datePickerIsOpen: false,
      ageError: false,
    });
    this.debounceHandler()
  };
  openDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  convertFormat = () => {
    return (
      this.state.selectedDateFrom &&
      moment.parseZone(this.state.selectedDateFrom).format("DD MMM YYYY")
    );
  };
  handleChangesTo = (selectedDate: any) => {
    this.setState({
      selectedDateTo: moment(selectedDate).format("MM/DD/YY"),
      datePickerToIsOpen: false,
      ageError: false,
    });
    this.debounceHandler()
  };
  openDatePickerTo() {
    this.setState({
      datePickerToIsOpen: !this.state.datePickerToIsOpen,
    });
  }

  convertFormatTo = () => {
    return (
      this.state.selectedDateTo &&
      moment.parseZone(this.state.selectedDateTo).format("DD MMM YYYY")
    );
  };

  getSubstituteTeacherList = async () => {
    const token = await storage.get("loginToken");

    const header = { token };

    const getSubstituteTeacherList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.teacherListId = getSubstituteTeacherList.messageId;

    getSubstituteTeacherList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/substitute_teacher_list`
    );

    getSubstituteTeacherList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSubstituteTeacherList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(
      getSubstituteTeacherList.id,
      getSubstituteTeacherList
    );
  };

  toggleDrawer = (isOpen: boolean) => {
    this.setState({ isDrawerOpen: isOpen });
  };
}

// Customizable Area End
