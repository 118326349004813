// Customizable Area Start
Object.defineProperty(exports, '__esModule', {
  value: true
});


exports.compnaypagetitle = 'COMPANY PAGE';

exports.getcompanyApiContentType = 'application/json';
exports.getCompanyEndPoint = 'bx_block_joblisting/company_pages';
exports.getcompanyApiMethod = 'GET';
exports.ApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.searchJobApiEndPoint = "bx_block_joblisting/job_creations/search_job";
exports.searchPeopleApiEndPoint = "bx_block_search/search_people";
exports.searchCompanyApiEndPoint = "bx_block_search/search_companies";

exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'JobListing';
exports.labelBodyText = 'JobListing Body';
exports.emptyList = 'No Data Found';
exports.btnExampleTitle = 'CLICK ME';
exports.getCreateIdEndPoint='bx_block_chat/chats/create_conversation'

// Manage people
exports.pageMemberLabel = 'Company page admins';
exports.pageMemberNote = 'View, Edit or Add member to you company page';

// add people
exports.addPeople = 'ADD PEOPLE';

// buttons
exports.continue_btn = 'Continue';
exports.continuetopage = 'Go to company page';
exports.addMember = 'Add New Admin';

exports.experienceTxt = 'EXPERIENCE';
exports.educationLevelTxt = 'EDUCATION LEVEL';
exports.locationTxt = 'LOCATION';
exports.postedTxt = 'Posted';
exports.colonTxt = ':';
exports.saveJobPost = 'Save job post';
exports.jobPostSaved = 'Job post saved';
exports.jobPostSavedSnackbarTxt = 'Job Post saved successfully!';
exports.jobPostRemovedSnackbarTxt = 'Job Post removed from Saved Jobs!';
exports.postedByTxt = 'Posted by';
exports.sendMessage = 'Send message';
exports.middleDotTxt = '•';
exports.jobDescriptionTxt = 'Job Description';
exports.jobTitleTxt = 'Job Title';
exports.jobSummaryTitle = 'Job Summary';
exports.keyResponsibilities = 'Key Responsibilities';
exports.qualificationsTxt = 'Qualifications';
exports.benefitsTxt = 'Benefits';
exports.aboutOrganization = 'About Organization';
exports.applyForJob = 'Apply for Job';
exports.homeTxt = 'Home';
exports.searchResultsTxt = 'Search results';
exports.jobDetailsTxt = 'Job details';
exports.successfullyAppliedTxt= 'Successfully applied!';
exports.thanksForApplied = 'Thank you for your application. Our school admin will review it and get back to you soon!';
exports.browseMoreJob = 'Browse more jobs';
exports.YouMayInterestedTxt = 'You may be interested in';
exports.viewAll = 'View all';
exports.viewDetails = 'View details';
exports.navbarTitle = 'Job Details';
exports.inviteFriend = "Invite Friend"
// Customizable Area End
