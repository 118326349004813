import { Route } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import EmailAccountLoginBlock from '../../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const publicRoute = ['/','/EmailAccountLoginBlock','/EmailAccountRegistration', '/OtpConfirmation','/Verification','/VerificationSentPending','/ForgotPassword','/ForgotPassword','/NavigationMenu',
'/TermsConditions','/PrivacyFaq','/JobListing','/ResetPassword','/CustomForm','/Rolesandpermissions2']
const isPublicRoute = (path, publicRoutes) => {
  return publicRoutes.some(route => {
    const regex = new RegExp(`^${route.replace(/:\w+/g, '\\w+')}$`, 'i');
    return regex.test(path);
  });
};

const AuthRoute = ({ Component, props, routeMap, publicRoute }) => {
  const publicPath = props.location.pathname;
  const isLoggedIn = Boolean(localStorage.getItem('login-token'));
  useEffect(() => {
    if (!isLoggedIn && !isPublicRoute(publicPath, publicRoute)) {

      props.history.push("/dummy");

      props.history.replace("/EmailAccountLoginBlock");
    }

    if (publicPath === "/EmailAccountLoginBlock") {
      const unblock = props.history.block((location, action) => {
        if (action === "POP") {
          props.history.replace("/EmailAccountLoginBlock");
          return false;
        }
        return true;
      });

      return () => {
        unblock();
      };
    }
  }, [isLoggedIn, publicPath, props.history, publicRoute]);

  if (isLoggedIn || isPublicRoute(publicPath, publicRoute)) {
    return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
  }else {
    const publicPath = props.location.pathname;
    const isPublicRoute = publicRoute.find(route => route.toLowerCase() === publicPath.toLowerCase());
if (isPublicRoute) {
    return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;

}
else{  toast.warning("You need to log in first!");
  return <Wrapper element={<EmailAccountLoginBlock />} {...props} routeMap={routeMap} />;
}
  }
};

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <AuthRoute 
            Component={Component} 
            props={props} 
            routeMap={routeMap} 
            publicRoute={publicRoute} 
          />
          )}
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
